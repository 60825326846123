import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { ITermsAndConditions } from './TermsAndConditions.d';
import style from './style.scss';

/**
 * Terms and conditions
 *
 * Terms and conditions component allowing to link for terms page.
 **/
@Component({})
export class TermsAndConditions extends BaseComponent<ITermsAndConditions, unknown> {
  /**
   * Determines divider
   */
  @Prop({ type: Object, required: true })
  reviewAgreed: I18nMessage;

  /**
   * A text link representing for cms page
   */
  @Prop({ type: Object, required: true })
  reviewPrivacyPolicy: I18nMessage;

  /**
   * A text link representing for cms page
   */
  @Prop({ type: Object, required: true })
  reviewTerms: I18nMessage;

  /**
   * Link for terms and conditions page if provided
   * Default to value from config
   */
  @Prop({ type: String, required: false, default: '' })
  termsAndConditionsLink?: string;

  /**
   * Link for privacy policy page if provided
   * Default to value from config
   */
  @Prop({ type: String, required: false, default: '' })
  privacyPolicyLink?: string;

  beforeMount() {
    this.closeModal = this.closeModal.bind(this);
  }

  get termsAndConditionsUri() {
    return this.termsAndConditionsLink || this.extended.$config.zento.theme.termsAndConditionsPageLink;
  }

  get privacyPolicyUri() {
    return this.privacyPolicyLink || this.extended.$config.zento.theme.privacyPolicyPageLink;
  }

  render() {
    return (
      <span class={style.agreeBox}>
        <span onClick_capture={this.closeModal}>
          <a href={this.termsAndConditionsUri} target='_blank'>
            {this.getTranslation(this.reviewTerms)}
          </a>
        </span>
        {this.getTranslation(this.reviewAgreed)}
        <span onClick_capture={this.closeModal}>
          <a href={this.privacyPolicyUri} target='_blank' handler={this.closeModal}>
            {this.getTranslation(this.reviewPrivacyPolicy)}
          </a>
        </span>
      </span>
    );
  }

  private closeModal() {
    this.$store.commit('myAccount/setModalActive', false);
    this.$store.commit('myAccount/setDrawerActive', false);
    this.$store.commit('ui/setOverlay', false);
    document.body.style.overflow = 'visible';
  }
}
